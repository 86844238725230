import './css/intro.css'
import './css/quote-star.css'
import './css/texts.css'
import './css/outro.css'
import './style.css'
import * as THREE from 'three'
//import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import virtualScrollHandler from 'virtual-scroll-handler'
import Lottie from 'lottie-web'
import { createNoise2D } from 'simplex-noise';


///


var lateral = 0;
var frontal = 0;

//const noise2D = createNoise2D();

var device;

if (window.innerWidth > window.innerHeight) {
    device = "desktop"
} else {
    device = "mobile"
}

/**
 * Lottie
 */


/*
snowflakes = Lottie.loadAnimation({
    container: document.getElementById('snowflakes'),
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: 'lotties/' + device + '/snow.json',
})
*/

const soundSnow = new Audio('sounds/snow.wav');
const soundPaper = new Audio('sounds/paper.wav');
const soundFlower = new Audio('sounds/flower.wav');
const soundFire = new Audio('sounds/fireworks.wav');

//anim

var sketches, flowerdior, fireworks,snowflakes;


// Tools
const lerp = (x, y, a) => x * (1 - a) + y * a;
const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));
const invlerp = (x, y, a) => clamp((a - x) / (y - x));
const range = (x1, y1, x2, y2, a) => lerp(x2, y2, invlerp(x1, y1, a));


// Variables
var direction = "";
var state = "begin";

var mixer;

// DOM
const canvas = document.querySelector('canvas.webgl')
const step1 = document.getElementById('step-1')
const step2 = document.getElementById('step-2')
const step3 = document.getElementById('step-3')
const step4 = document.getElementById('step-4')
const explore = document.querySelector('.explore')

const snowflakes1 = document.querySelector('#snowflakes')
const quoteStar = document.querySelector('.quote-star')

const whiteGradient = document.querySelector('.white-sky')


const text1 = document.querySelector('#text1')


const textStep1 = document.querySelector('#textStep1')
const textStep2 = document.querySelector('#textStep2')
const textStep3 = document.querySelector('#textStep3')
const textStep4 = document.querySelector('#textStep4')
const textStep5 = document.querySelector('#textStep5')

const outro = document.querySelector('.outro')

const gradientLast = document.querySelector('.gradientLast')

var skyz = [
    document.querySelector('.gradient1'),
    document.querySelector('.gradient2'),
    document.querySelector('.gradient3'),
    document.querySelector('.gradient4'),
    document.querySelector('.gradient5'),
    document.querySelector('.gradient6'),
    document.querySelector('.gradient7'),
    document.querySelector('.gradient8'),
    document.querySelector('.gradient9'),
    document.querySelector('.gradient10'),
    document.querySelector('.gradient11'),
]

var textTof = [
    document.querySelector('#text2'),
    document.querySelector('#empty'),
    document.querySelector('#text3'),
    document.querySelector('#text4'),
    document.querySelector('#text5'),
    document.querySelector('#text6'),
    document.querySelector('#text7'),
    document.querySelector('#empty'),
    document.querySelector('#text8'),
    document.querySelector('#text9'),
    document.querySelector('#text10'),
    document.querySelector('#empty'),
    document.querySelector('#text11'),
    document.querySelector('#empty'),
    document.querySelector('#text12')
]


var lotties = [
    document.querySelector('#sketches'),
    document.querySelector('#flowerdior'),
    document.querySelector('#fireworks'),

]


// points

const checkpoints = [

    { x: .25, y: 1, z: -5 },//begin
    { x: .25, y: 0, z: -10 },//intro
    { x: .25, y: 0, z: -12 },
    { x: .25, y: 0, z: -16 },//picture1
    { x: .25, y: 0, z: -19, step: textStep1, sky: 2 },
    { x: -.7, y: 0, z: -22 },//fee 
    { x: -1.5, y: 0, z: -26 },//turn
    { x: -1, y: 0, z: -29 },//turn 
    { x: -.5, y: 0, z: -32 },
    { x: -1.4, y: 0, z: -37 },
    { x: -3.2, y: 0, z: -42 },
    { x: -4.5, y: 0, z: -47 },
    //{ x: -4.5, y: 0, z: -50 },
    { x: -4.1, y: 0, z: -52 },
    { x: -3.1, y: 0, z: -57, step: textStep2, sky: 3 },
    { x: -2.5, y: 0, z: -62 },
    { x: -2, y: 0, z: -67 },
    { x: -2.2, y: 0, z: -72 },
    { x: -2.6, y: 0, z: -77 },
    { x: -3, y: 0, z: -82 },
    { x: -2.4, y: 0, z: -87, step: textStep5 },
    { x: -.8, y: 0, z: -92 },
    { x: .8, y: 0, z: -97 },
    { x: 1.6, y: 0, z: -102 },
    { x: 1.3, y: 0, z: -107, step: textStep3, sky: 5 },
    { x: .8, y: 0, z: -112 },//chien
    { x: .9, y: 0, z: -117 },
    { x: 1.2, y: 0, z: -122 },
    { x: 1.3, y: 0, z: -127 },
    { x: 1.2, y: 0, z: -132 },
    { x: 1.2, y: 0, z: -138, sky: 7 },
    { x: 1.6, y: 0, z: -145 },
    { x: 2.4, y: 0, z: -153, step: textStep4 },
    { x: 3.6, y: 0, z: -162 },//abeille
    { x: 2, y: 0, z: -170 },
    { x: -1, y: 0, z: -177, sky: 9 },
    { x: -3.5, y: 0, z: -187 },
    { x: -4.3, y: .25, z: -197 },
    { x: -3.8, y: .75, z: -209 },
    { x: -3.3, y: 1.5, z: -213 },
    { x: -2.9, y: 1.5, z: -216 },
    { x: -2.9, y: 1.5, z: -216 },

    /*
     { x: -2.8, y: 1.5, z: -223 },
    { x: -2.4, y: 1.5, z: -226 },
    { x: -1, y: 1.5, z: -234 },
    { x: -1, y: 1.5, z: -234 },
    */
]

//console.log(checkpoints.length)



// Scene
const scene = new THREE.Scene()
scene.fog = new THREE.FogExp2(0xfffef9, 0.05);

/**
 * Sizes
 */
var sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

/**
 * Camera
 */

const pov = device == "destkop" ? 45 : 55;
const camera = new THREE.PerspectiveCamera(pov, sizes.width / sizes.height, 0.1, 100)

camera.position.y = 0;

scene.add(camera)

var target = new THREE.Vector3();
//target.y=.15;



/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true
})


renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(window.devicePixelRatio);

window.addEventListener('resize', onWindowResize);
onWindowResize();

function onWindowResize() {

    if (window.innerWidth > window.innerHeight) {
        device = "desktop"
    } else {
        device = "mobile"
    }


    sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }

    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();
    renderer.setSize(sizes.width, sizes.height);
}


/**
 * Orbit controls
 */

/*
var controls;

if (controls_active) {
    controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
}
*/

/**
 * Loading manager
 */

const loadingManager = new THREE.LoadingManager(
    // Loaded
    () => {
        requestAnimationFrame(animate);

        document.querySelector('.cta-start').classList.remove('off')
        document.querySelector(' .preload-wrapper').classList.add('off')


        
        sketches = Lottie.loadAnimation({
            container: document.getElementById('sketches'),
            renderer: 'svg',
            loop: true,
            autoplay: false,
            path: 'lotties/' + device + '/paper.json',
        })
        
        flowerdior = Lottie.loadAnimation({
            container: document.getElementById('flowerdior'),
            renderer: 'svg',
            loop: true,
            autoplay: false,
            path: 'lotties/' + device + '/flowerdior.json',
        })
        
        
        
        
        fireworks = Lottie.loadAnimation({
            container: document.getElementById('fireworks'),
            renderer: 'svg',
            loop: true,
            autoplay: false,
            path: 'lotties/' + device + '/fireworks.json',
        })
        
        /*
         snowflakes = Lottie.loadAnimation({
            container: document.getElementById('snowflakes'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: 'lotties/' + device + '/snow.json',
        })
*/        

    },

    // Progress
    (itemUrl, itemsLoaded, itemsTotal) => {
        const progressRatio = itemsLoaded / itemsTotal

        document.querySelector('.preloader-bar').style.width = Math.round(itemsLoaded / itemsTotal * 100) + "%";
       //console.log(itemUrl, itemsLoaded, itemsTotal)
    }
)



/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader(loadingManager);
const goldTexture = textureLoader.load('textures/gold7.jpg')

const goldRTexture = textureLoader.load('textures/gold7.jpg')
goldRTexture.flipY = false;

const whiteTexture = textureLoader.load('textures/beige.jpg')
const blueTexture = textureLoader.load('textures/blue3.jpg')
const brownTexture = textureLoader.load('textures/brown.jpg')
const beeTexture = textureLoader.load('textures/bee.jpg')
const blackTexture = textureLoader.load('textures/black_eye.jpg')

const dice = textureLoader.load('textures/dice.png')

const bredTexture = textureLoader.load('textures/ginger.jpg')
const ailesTexture = textureLoader.load('textures/Image_3.jpg')
ailesTexture.flipY = false;

const silverTexture = textureLoader.load('textures/silver.jpg')
const flowerTexture = textureLoader.load('textures/flower3.jpg')
const woodTexture = textureLoader.load('textures/wood.jpg')

const shadowTexture = textureLoader.load('textures/shadow.png')

const text1Texture = textureLoader.load('textures/textes/text1.png')
const text2Texture = textureLoader.load('textures/textes/text2.png')
const text3Texture = textureLoader.load('textures/textes/text3.png')
const text4Texture = textureLoader.load('textures/textes/text3.png')


const pan1Texture = textureLoader.load('textures/photos/01a_ombre.png')
const pan2Texture = textureLoader.load('textures/photos/01b_ombre.png')
const pan3Texture = textureLoader.load('textures/photos/01c_ombre.png')
const pan4Texture = textureLoader.load('textures/photos/01d_ombre.png')
const pan5Texture = textureLoader.load('textures/photos/01e_ombre.png')
const pan6Texture = textureLoader.load('textures/photos/02a_ombre.png')
const pan7Texture = textureLoader.load('textures/photos/02b_ombre.png')
const pan8Texture = textureLoader.load('textures/photos/02c_ombre.png')
const pan9Texture = textureLoader.load('textures/photos/03a_ombre.png')
const pan10Texture = textureLoader.load('textures/photos/03b_ombre.png')
const pan11Texture = textureLoader.load('textures/photos/04_ombre.png')
/**
 * Material
 */
const gold = new THREE.MeshMatcapMaterial({ matcap: goldTexture });
const goldR = new THREE.MeshMatcapMaterial({ matcap: goldRTexture });

const white = new THREE.MeshMatcapMaterial({ matcap: whiteTexture });
const blue = new THREE.MeshMatcapMaterial({ matcap: blueTexture, side: THREE.DoubleSide });
const brown = new THREE.MeshMatcapMaterial({ matcap: brownTexture });

const black = new THREE.MeshBasicMaterial({ color: 'black' })

/**
 * Objects
 */

//const geometry = new THREE.SphereGeometry(.02,16,16);

const geometry = new THREE.CylinderGeometry(.02, .02, .5, 32);
var points = [];
var Steps = [];
var gradients = [];


checkpoints.forEach(point => {
    //console.log(point)

    points.push(new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({ color: 'black', transparent: true, opacity: 1 })))

    points[points.length - 1].position.x = point.x
    points[points.length - 1].position.y = 0
    points[points.length - 1].position.z = point.z

    //scene.add(points[points.length - 1])

    if (point.step) {
        Steps.push({ point: points[points.length - 1], text: point.step })
    }

    if (point.sky) {
        gradients.push({ point: points[points.length - 1], sky: skyz[point.sky] })
    }
})

//console.log(gradients)


let GLBloader = new GLTFLoader();
var world;

GLBloader.load('objects/world8.gltf', function (gltf) {
    gltf.scene.traverse(function (child) {

        //console.log(child.name)
        child.material = white

        if (child.name.substring(0, 2) == "G_"
            || child.name == "Tête"
            || child.name == "Tête-Mat"
            || child.name == "Tête-Gold"
            || child.name == "Tête-Mat2"
            || child.name == "W_G_Tour_Effeil-Gold"
            || child.name == "W_G_Tour_Effeil-Gold_1"
        ) {
            child.material = gold
        }

        if (child.name == "G_Etoilebaguette"
        ) {
            child.material = goldR
        }



        if (child.name.substring(0, 2) == "B_") {
            child.material = blue
        }

        //flower
        if (child.name.substring(0, 2) == "F_") {
            child.material = new THREE.MeshMatcapMaterial({ matcap: flowerTexture });
        }

        if (child.name == "Sweep1_15" || child.name == "Sweep1_17") {
            // child.material = new THREE.MeshBasicMaterial({ color: 'black' })
        }




        if (child.name == "Tête-Mat"
            || child.name == "Tube_2"
            || child.name == "Bee1_remesh-Mat4"
            || child.name == "Bee1_remesh"
            || child.name == "Abeille"
            || child.name == "Abeille_Scene"
            || child.name.substring(0, 1) == "D"
            || child.name.substring(0, 2) == "M_"
            || child.name.substring(0, 2) == "A_"
        ) {
            child.material = brown
        }

        if (
            child.name == "Bee1_remesh-Mat4"
            || child.name == "Bee1_remesh"
            || child.name == "Abeille"
            || child.name == "Abeille_Scene"
            || child.name.substring(0, 2) == "A_"
        ) {
            child.material = new THREE.MeshMatcapMaterial({ matcap: beeTexture });
        }




        if (child.name.substring(0, 2) == "P_"
        ) {
            child.material = new THREE.MeshMatcapMaterial({ matcap: bredTexture })
        }

        if (child.name.substring(0, 3) == "BL_"
        ) {
            child.material = black
        }

        if (child.name == "Tête-Mat3"
            || child.name == "Bee1_remesh-Mat3"
        ) {
            child.material = new THREE.MeshMatcapMaterial({ matcap: blackTexture });
        }


        if (child.name.substring(0, 4) == "Skin" || child.name.substring(0, 4) == "SKIN") {
            child.material = new THREE.MeshMatcapMaterial({ matcap: woodTexture });

        }

        if (child.name == 'Skin_Baguette') {
            child.material = brown;
        }


        if (child.name.substring(0, 4) == "Pink"
        ) {
            child.material = new THREE.MeshBasicMaterial({ color: 'Crimson' })
        }

        if (child.name.substring(0, 4) == "Rose"
        ) {
            child.material = new THREE.MeshBasicMaterial({ color: 0xE8A4CA })

        }

        if (child.name.substring(0, 2) == "S_"
        ) {
            child.material = new THREE.MeshMatcapMaterial({ matcap: silverTexture });
        }

        if (child.name.substring(0, 4) == "AILE"
        ) {
            child.material = new THREE.MeshBasicMaterial({ map: ailesTexture })
        }


    })

    world = gltf.scene
    world.scale.set(.01, .01, .01)
    world.position.y = -1;
    world.position.x = 5;
    scene.add(world);


    mixer = new THREE.AnimationMixer(gltf.scene);
    var action = mixer.clipAction(gltf.animations[0]);
    action.play();

    //console.log(gltf.animations[0])

})

/**
 * Scroll
 */


const tofPoint = (tof, point) => {

    tof.position.x = point.x;
    tof.position.y = point.y;
    tof.position.z = point.z;
}

var tofs = []


tofs.push(new THREE.Mesh(new THREE.PlaneGeometry(1.036, 1.376), new THREE.MeshBasicMaterial({ map: pan1Texture, transparent: true, opacity: 0, })));
scene.add(tofs[0]);
tofPoint(tofs[0], checkpoints[3])


//phase1

tofs.push(new THREE.Mesh(new THREE.PlaneGeometry(1.24, 1.5), new THREE.MeshBasicMaterial({ map: text1Texture, transparent: true, opacity: 0 })));
scene.add(tofs[1]);
tofs[1].scale.set(.8, .8, .8)
tofPoint(tofs[1], checkpoints[7])



tofs.push(new THREE.Mesh(new THREE.PlaneGeometry(1.156, 1.492), new THREE.MeshBasicMaterial({ map: pan2Texture, transparent: true, opacity: 0 })));
scene.add(tofs[2]);
tofPoint(tofs[2], checkpoints[9])

tofs.push(new THREE.Mesh(new THREE.PlaneGeometry(1.592, 1.592), new THREE.MeshBasicMaterial({ map: pan3Texture, transparent: true, opacity: 0 })));
scene.add(tofs[3]);
tofPoint(tofs[3], checkpoints[10])

tofs.push(new THREE.Mesh(new THREE.PlaneGeometry(1.592, 1.592), new THREE.MeshBasicMaterial({ map: pan4Texture, transparent: true, opacity: 0 })));
scene.add(tofs[4]);
tofPoint(tofs[4], checkpoints[11])

tofs.push(new THREE.Mesh(new THREE.PlaneGeometry(1.592, 1.592), new THREE.MeshBasicMaterial({ map: pan5Texture, transparent: true, opacity: 0 })));
scene.add(tofs[5]);
tofs[5].scale.set(.7, .7, .7)
tofPoint(tofs[5], checkpoints[12])

// phase2

tofs.push(new THREE.Mesh(new THREE.PlaneGeometry(1.036, 1.376), new THREE.MeshBasicMaterial({ map: pan6Texture, transparent: true, opacity: 0 })));
scene.add(tofs[6]);
tofPoint(tofs[6], checkpoints[16])

tofs.push(new THREE.Mesh(new THREE.PlaneGeometry(1.424, 1.684), new THREE.MeshBasicMaterial({ map: text2Texture, transparent: true, opacity: 0 })));
scene.add(tofs[7]);
tofs[7].scale.set(.8, .8, .8)
tofPoint(tofs[7], checkpoints[18])

tofs.push(new THREE.Mesh(new THREE.PlaneGeometry(1.592, 1.592), new THREE.MeshBasicMaterial({ map: pan7Texture, transparent: true, opacity: 0 })));
scene.add(tofs[8]);
tofPoint(tofs[8], checkpoints[21])

tofs.push(new THREE.Mesh(new THREE.PlaneGeometry(1.156, 1.492), new THREE.MeshBasicMaterial({ map: pan8Texture, transparent: true, opacity: 0 })));
scene.add(tofs[9]);
tofPoint(tofs[9], checkpoints[22])


// phase3

tofs.push(new THREE.Mesh(new THREE.PlaneGeometry(1.424, 1.424), new THREE.MeshBasicMaterial({ map: pan9Texture, transparent: true, opacity: 0 })));
scene.add(tofs[10]);
tofPoint(tofs[10], checkpoints[28])


tofs.push(new THREE.Mesh(new THREE.PlaneGeometry(1.424, 1.684), new THREE.MeshBasicMaterial({ map: text3Texture, transparent: true, opacity: 0 })));
scene.add(tofs[11]);
tofs[11].scale.set(.8, .8, .8)
tofPoint(tofs[11], checkpoints[30])

//phase 4

tofs.push(new THREE.Mesh(new THREE.PlaneGeometry(2.128, 1.508), new THREE.MeshBasicMaterial({ map: pan10Texture, transparent: true, opacity: 0 })));
scene.add(tofs[12]);
tofs[12].scale.set(.7, .7, .7)
tofPoint(tofs[12], checkpoints[34])

tofs.push(new THREE.Mesh(new THREE.PlaneGeometry(1.532, 1.668), new THREE.MeshBasicMaterial({ map: text4Texture, transparent: true, opacity: 0 })));
scene.add(tofs[13]);
tofs[13].scale.set(.8, .8, .8)
tofPoint(tofs[13], checkpoints[35])

tofs.push(new THREE.Mesh(new THREE.PlaneGeometry(2.246, 1.492), new THREE.MeshBasicMaterial({ map: pan11Texture, transparent: true, opacity: 0 })));
scene.add(tofs[14]);
tofs[14].scale.set(.6, .6, .6)
tofPoint(tofs[14], checkpoints[36])



/**
 * Lights  // shadow
 */


/*
 const color = 0xFFFFFF;
 const intensity = 1;
 const light = new THREE.DirectionalLight(color, intensity);
 light.position.set(2, 5, -23);
 light.target.position.set(0, 0, -25);
 light.castShadow = true;
 scene.add(light);
 scene.add( light.target );

 light.shadow.mapSize.width = 4048;
 light.shadow.mapSize.height = 4048;

*/
/*
var shadowLight = new THREE.PointLight(0xffffff, 0.1);
shadowLight.position.set(2, 5, -24);
shadowLight.castShadow = true;
shadowLight.shadow.mapSize.width = 2048;
shadowLight.shadow.mapSize.height = 2048;
scene.add(shadowLight);

var light = new THREE.PointLight(0xffffff, 0.9);
light.position.set(2, 5, -24);
scene.add(light);

    const helper = new THREE.CameraHelper( light.shadow.camera );
    scene.add( helper );


 const geo = new THREE.PlaneGeometry( 10, 10 );
 const material = new THREE.MeshStandardMaterial ( {color: 0xffffff} );
 const plane = new THREE.Mesh( geo, material );
 scene.add( plane );
 plane.rotation.x=-Math.PI/2;
plane.position.y=-.99;
plane.position.z=-20;
plane.receiveShadow = true;


const geoS = new THREE.SphereGeometry( .1, 32, 16 );
const sphere = new THREE.Mesh( geoS, material );
scene.add( sphere );

sphere.position.z=-20;
sphere.position.y=-.75;
sphere.castShadow=true

*/


const geo = new THREE.PlaneGeometry(2.4, 2.4);
const geo2 = new THREE.PlaneGeometry(7, 7);

const material = new THREE.MeshBasicMaterial({ map: shadowTexture, transparent: true, opacity: .25 });

const shadow1 = new THREE.Mesh(geo, material);
scene.add(shadow1);
shadow1.rotation.x = -Math.PI / 2;
shadow1.position.y = -.99;
shadow1.position.z = -22;
shadow1.position.x = -.24;


const shadow2 = new THREE.Mesh(geo, material);
scene.add(shadow2);
shadow2.rotation.x = -Math.PI / 2;
shadow2.position.y = -.99;
shadow2.position.z = -60;
shadow2.position.x = -2.8;



const shadow3 = new THREE.Mesh(geo, material);
scene.add(shadow3);
shadow3.rotation.x = -Math.PI / 2;
shadow3.position.y = -.99;
shadow3.position.z = -114.5;
shadow3.position.x = .8;


const shadow4 = new THREE.Mesh(geo, material);
scene.add(shadow4);
shadow4.rotation.x = -Math.PI / 2;
shadow4.position.y = -.99;
shadow4.position.z = -165;
shadow4.position.x = 3.2;

const shadow5 = new THREE.Mesh(geo2, material);
scene.add(shadow5);

shadow5.rotation.x = -Math.PI / 2;
shadow5.position.y = -.99;
shadow5.position.z = -224;
shadow5.position.x = -1.85;


/**
 * Scroll
 */

let scroller = new virtualScrollHandler({
    range: [0, 500],
    createScrollbar: false,
    fadeOutDelay: 500,
    lerpAmount: .05,
    lerpAmountSwipe: .05,
    mobileSensibility: .5,
});


var starting = 0;
requestAnimationFrame(scroller_animate);

function scroller_animate() {
    scroller.update();


    //intro
    if (state == "begin") {

        starting = lerp(starting, 1, .1)

        step1.style.opacity = starting;
        step1.style.transform = `translateY(${20 - 20 * starting}px)`

        if (starting > .9) {
            state = "intro"
            document.querySelector('.explore').classList.remove('off')
        }
    }


    if (state == "intro") {

        step1.style.opacity = range(0, 0.2, 1, 0, scroller.completion)
        step1.style.transform = `translateY(${range(0, 0.2, 0, -20, scroller.completion)}px)`

        step2.style.opacity = range(0.2, 0, 0, 1, (Math.abs(scroller.completion - 0.4)))
        step2.style.transform = `translateY(${range(-0.2, 0.2, 20, -20, scroller.completion - 0.4)}px)`

        /*
        step3.style.opacity = range(0.1, 0, 0, 1, (Math.abs(scroller.completion - 0.5)))
        step3.style.transform = `translateY(${range(-0.1, 0.1, 20, -20, scroller.completion - 0.5)}px)`
        */

        step4.style.opacity = range(0.65, .9, 0, 1, scroller.completion)
        step4.style.transform = `translateY(${range(0.65, .9, 20, 0, scroller.completion)}px)`

        explore.style.opacity = range(0.65, .9, 1, 0, scroller.completion)
        snowflakes1.style.opacity = scroller.completion;

        //console.log(scroller.completion)
    }

    requestAnimationFrame(scroller_animate);
}

/**
 * Animate
 */

var speed = .01;
var tick = 0;
var time = 0;

function animate() {
    requestAnimationFrame(animate);

    time += 0.002;

    /*
    if (controls_active) {
        controls.update();
    }
*/


    //console.log(direction)
    var cible = shadow5;
/*
    switch (direction) {
        case "ArrowLeft":
            lateral -= speed;
            cible.position.x -= speed;
            break;
        case "ArrowRight":
            lateral += speed;
            cible.position.x += speed;
            break;
        case "ArrowUp":
            frontal -= speed;
            cible.position.z -= speed;
            break;
        case "ArrowDown":
            frontal += speed;
            cible.position.z += speed;
            break;
        case "Space":
            //console.log(cible.position)

            break;
        case "Numpad0":
            frontal = 0;
            lateral = 0;
            break;
    }
*/

    if (mixer) {
        tick += 2;
        tick %= 352;

        mixer.setTime(tick / 100)
    }

    if (state == "exp") {
        if (world) {


            //move camera
            var step = range(0, 1, 0, points.length, scroller.completion);
            var progress_step = step - Math.floor(step);

            var nStep = Math.floor(step)

            //console.log(nStep)


            if (nStep < points.length - 3) {

                var dz = checkpoints[nStep + 1].z - checkpoints[nStep].z
                var dx = checkpoints[nStep + 1].x - checkpoints[nStep].x
                var dy = checkpoints[nStep + 1].y - checkpoints[nStep].y

                camera.position.z = checkpoints[nStep].z + dz * progress_step //+ frontal
                camera.position.x = checkpoints[nStep].x + dx * progress_step //+ lateral
                camera.position.y = checkpoints[nStep].y + dy * progress_step


                var dtz = checkpoints[nStep + 2].z - checkpoints[nStep + 1].z
                var dtx = checkpoints[nStep + 2].x - checkpoints[nStep + 1].x

                var prog = range(0, 1, -4, 0, progress_step)

                target.z = checkpoints[nStep + 1].z + dtz * Math.exp(prog) //+ frontal
                target.x = checkpoints[nStep + 1].x + dtx * Math.exp(prog) //+ lateral+noise2D(0,time)/5 //Math.exp(prog)
                target.y = camera.position.y //+noise2D(10000,time)/20

            }


            //intro

            if (nStep == 0) {

                if (progress_step > 0) {
                    quoteStar.classList.add('anim')
                }

                quoteStar.style.transform = `translateY(${-progress_step * 75}%)`
                quoteStar.style.opacity = range(.8, 1, 1, 0, progress_step)

                snowflakes1.style.transform = `translateY(${range(0, 1, 0, -30, progress_step)}%)`
                snowflakes1.style.opacity = 1 - progress_step

                skyz[1].style.opacity = range(.4, .8, 0, 1, progress_step)

                whiteGradient.style.opacity = range(.3, .5, 0, 1, progress_step)
                whiteGradient.style.transform = `translateY(${range(.3, 1, 75, 0, progress_step)}%)`

                target.y = camera.position.y + (1 - progress_step) * 3

                text1.style.transform = `translateY(${range(.3, 1, 100, 0, progress_step)}%)`

                //snowflakes.play();
            }

            //step1

            if (nStep == 1) {
                //snowflakes.stop();
                text1.style.opacity = range(0, .7, 1, 0, progress_step)
            }

            if(nStep>=1&&nStep<15){
                whiteGradient.style.transform = `translateY(0)`
                text1.style.transform = `translateY(0%)`
                quoteStar.style.transform = `translateY(-75%)`

                whiteGradient.style.opacity=1
                snowflakes1.style.opacity =0
                quoteStar.style.opacity = 0
            }


            //lotties
            if (state == "exp") {

                //papers




                if (nStep < 5) {
                    lotties[0].style.opacity = 0
                    sketches.stop();
                }

                if (nStep == 5) {
                    lotties[0].style.opacity = range(.5, 1, 0, 1, progress_step)
                    sketches.play();
                    sketches.loop = false;
                    soundPaper.volume=progress_step;
                    
                }

                if (nStep == 6) {
                    lotties[0].style.opacity = 1
                    sketches.play();
                    soundPaper.play();
                    soundPaper.volume=1;
                    sketches.loop = true;
                }

                if (nStep == 7) {
                    lotties[0].style.opacity = range(0, 1, 1, 0, progress_step)
                    sketches.play();
                    sketches.loop = false;
                    soundPaper.volume=1-progress_step;
  
                }
                if (nStep > 7) {
                    lotties[1].style.opacity = 0
                    sketches.stop();
                }

                //papers

                if (nStep < 16) {
                    lotties[1].style.opacity = 0
                    flowerdior.stop();
                }

                if (nStep == 16) {
                    lotties[1].style.opacity = range(.5, 1, 0, 1, progress_step)
                    flowerdior.play();
                    flowerdior.loop = false;
                    soundFlower.volume=progress_step;
                }

                if (nStep == 17) {
                    lotties[1].style.opacity = 1
                    flowerdior.play();
                    flowerdior.loop = false;
                    soundFlower.play();
                    soundFlower.volume=1;
                }

                if (nStep == 18) {
                    lotties[1].style.opacity = range(0, 1, 1, 0, progress_step)
                    flowerdior.play();
                    flowerdior.loop = false;
                    soundFlower.volume=1-progress_step;
                }
                if (nStep > 18) {
                    lotties[1].style.opacity = 0
                    flowerdior.stop();
                }


                //fireworks

                if (nStep < 33) {
                    lotties[2].style.opacity = 0
                    fireworks.stop();
                }

                if (nStep == 33) {
                    lotties[2].style.opacity = range(.5, 1, 0, 1, progress_step)
                    fireworks.play();
                    fireworks.loop = false;
                    soundFire.volume=progress_step;
                }

                if (nStep == 34) {
                    lotties[2].style.opacity = 1
                    fireworks.play();
                    fireworks.loop = true;
                    soundFire.play();
                    soundFire.volume=1;
                }

                if (nStep == 35) {
                    lotties[2].style.opacity = range(0, 1, 1, 0, progress_step)
                    fireworks.play();
                    fireworks.loop = false;
                    soundFire.volume=1-progress_step;
                }
                if (nStep > 35) {
                    lotties[2].style.opacity = 0
                    fireworks.stop();
                }


            }

            //steps

            tofs.forEach((tof, index) => {

                var distance = camera.position.distanceTo(tof.position)

                if (distance < 25 && camera.position.z > tof.position.z) {

                    var alpha = range(25, 5, 0, 1, distance)
                    if (distance < 3) {
                        alpha = range(1, 0.5, 1, 0, distance)
                    }

                    tof.material.opacity = alpha
                    tof.lookAt(camera.position)

                }

                var alphaText;

                if (camera.position.z > tof.position.z + 3) {
                    alphaText = range(5, 3, 0, 1, distance)
                }
                if (camera.position.z < tof.position.z + 3) {

                    if (camera.position.z > tof.position.z) {
                        alphaText = range(3, 0, 1, 0, distance)
                    } else {
                        alphaText = 0
                    }

                }

                textTof[index].style.opacity = alphaText
            })



            Steps.forEach((step, index) => {
                step.text.style.opacity = range(3, 1, 0, 1, camera.position.distanceTo(step.point.position))
            })


            gradients.forEach((gradient, index) => {
                if (camera.position.z > gradient.point.position.z) {
                    gradient.sky.style.opacity = range(12, 0, 0, 1, camera.position.distanceTo(gradient.point.position))
                } else {
                    gradient.sky.style.opacity = 1;
                }
            })

            //outro

            if (nStep == points.length - 4) {
                //snowflakes.stop();
            }

            if (nStep >= points.length - 3) {
                var lastProgress = range(points.length - 3, points.length, 0, 1, step)

                camera.position.y = (lastProgress * 15) + 1.5
                target.y = 1.5 + (camera.position.y - 1.5) * 1.3

                whiteGradient.style.opacity = range(.7, 1, 1, 0, lastProgress)
                whiteGradient.style.transform = `translateY(${range(0, 1, 0, 75, lastProgress)}%)`
                outro.style.transform = `translateY(${range(0, 1, -100, 0, lastProgress)}%)`

                gradientLast.style.opacity = range(0, 1, 0, 1, lastProgress)

                snowflakes1.style.transform = `translateY(${range(0, 1, -100, -0, lastProgress)}%)`
                //snowflakes.play();
                snowflakes1.style.opacity = lastProgress
            }

            //console.log(camera.position)
            camera.lookAt(target)
            renderer.render(scene, camera);
        }
    }




}


/**
 * Key
 */

document.addEventListener('keydown', function (event) {
    direction = event.code;
});

document.addEventListener('keyup', function (event) {
    direction = "";
});




/**
* cta
*/

document.querySelector('.cta-start').addEventListener('click', () => {
    //console.log('start')
    state = "exp";

    document.querySelector('.intro').classList.add('off');
    document.querySelector('.quote-star').classList.add('active');

    scroller = new virtualScrollHandler({
        range: [0, checkpoints.length * 200],
        startY: 0,
        createScrollbar: false,
        fadeOutDelay: 500,
        lerpAmount: .02,
        lerpAmountSwipe: .03,
        mobileSensibility: 1,
    });

    soundSnow.play()
})